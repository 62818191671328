.pigBtn {
  contain: strict;
  /* overflow: hidden; */
  position: absolute;
  left: 0;
  top: 0;
  outline-style: solid;
  outline: 0;
  will-change: transform, width, height, outline-color;
  padding: 0;
  margin: 0;
  border: 0;
  padding: 0;
  text-align: left;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

/* .pigBtn:hover,
.pigBtn:focus {
  outline: 6px solid yellow;
} */

.pigBtn:hover .caption,
.pigBtn:focus .caption {
  transform: none;
}

.pigImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.pigFull {
  opacity: 0;
  transition: opacity 150ms linear;
  will-change: opacity;
}

.pigThumbnail {
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

.pigThumbnailLoaded {
  /* just in case it might help performance */
  filter: none;
}

.pigFullLoaded {
  opacity: 1;
}

/* .caption {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  pointer-events: none;
  width: 100%;
  padding: 8px;
} */
/*
.caption::after {
  content: '';
  background-image: linear-gradient(transparent,rgba(0,0,0,.105),rgba(0,0,0,.4));
  top: 0;
  left: 0;
  bottom: -10px;
  right: 0;
  position: absolute;
  z-index: -1;
} */

.pigBtn:hover .checkbox {
  opacity: 0.5;
  transition: all 200ms linear;
}

.pigBtn:hover .checkbox:checked {
  opacity: 1;
}

.checkbox {
  opacity: 0;
}

.checkbox:checked {
  opacity: 1;
}

.overlaysTopLeft {
  position: absolute;
  top: 0px;
  left: 0px;
}

.overlaysTopRight {
  position: absolute;
  top: 0px;
  right: -4px;
}

.overlaysBottomLeft {
  position: absolute;
  bottom: 0px;
  left: 0px;
}
